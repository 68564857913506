import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ignoreElements } from 'rxjs-compat/operator/ignoreElements';
import { FieldConfig } from '../field.interface';
import { Constant } from 'src/app/constants/constant';
import { ProductListiingService } from 'src/app/services/product-listiing.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SubscriptionPriceService } from 'src/app/services/subscription-price.service';

@Component({
  exportAs: "dynamicForm",
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent {
  @Input() fields: FieldConfig[] = [];
  @Input() layout: any = 2;
  @Input() gapBetweenImage: any = '5px';
  @Input() displayMobileImage: any = 2;
  @Input() mobileGapBetweenImage: any = 2;
  @Input() customField13: any = 0;
  @Input() customField5: any = 0;

  @Input() newFormField: any = 0;

  objectTobeDisplayed: any;

  minusValue: any = '2px';
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  form: UntypedFormGroup;
  formInit: boolean = false;

  formInit1: boolean = false;

  enableConditionalField?: any;
  @Input() patchValueList: any[] = [];
  previousFormField: any;
  indexNew: number = 0;
  valuesInputed: any = [];
  dontDisplayBackButton: boolean = false; imageList: any = [];

  previousFormFieldsList: any[] = [];

  text: string;
  initSecondForm: boolean = false;
  formInitSecond: boolean = false;
  EnablePayment: any;

  get value() {
    return this.form.value;
  }

  constructor(private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private productListiingService: ProductListiingService,
    private subscriptionService : SubscriptionPriceService
  ) { }

  reInit: any = true;
  excludedInputTypes: Set<string> = new Set(['text']); 
  value1:any=[
    {
        "fieldId": 410,
        "label": "Select Vehicle Type",
        "name": "Select Vehicle Type",
        "inputType": "text",
        "options": "truck,car,bike",
        "collections": null,
        "type": "",
        "width": "",
        "value": null,
        "position": "",
        "customfield1": "1",
        "customfield2": "[{\"value\":\"truck\",\"image\":\"null223.jpg\"},{\"value\":\"car\",\"image\":\"null222.jpg\"},{\"value\":\"bike\",\"image\":\"null221.jpg\"}]",
        "customfield3": "1,null221.jpg",
        "customfield4": "0",
        "customfield5": "",
        "validations": null,
        "typeId": 1,
        "conditionText": "",
        "enableConditionalField": 0,
        "metaData": "{\"statement\":{\"groups\":[{\"conjunctor\":\"AND\",\"conditions\":[{\"filedName\":\"\",\"operators\":\"\",\"fieldValue\":\"\"}],\"groups\":[]}]}}",
        "gaTrackingId": null,
        "dynamicFormLabelValidator": [
          {
              "entityId": 429,
              "parentId": 419,
              "name": "minLength",
              "validator": "10",  // Minimum length validator
              "message": "Minimum length is 10 characters.",
              "hintMessage": "Please enter at least 10 characters."
          },
          {
              "entityId": 429,
              "parentId": 419,
              "name": "maxLength",
              "validator": "15",  // Maximum length validator
              "message": "Maximum length is 15 characters.",
              "hintMessage": "Please enter no more than 15 characters."
          }
      ],
        "operationType": null
    },
    {
        "fieldId": 404,
        "label": "Brand Of The Car",
        "name": "Brand Of The Car",
        "inputType": "image",
        "options": "AUDI,BENZ,BMW,Ford,Nissan,,,",
        "collections": null,
        "type": "",
        "width": "",
        "value": null,
        "position": "",
        "customfield1": "1",
        "customfield2": "[{\"value\":\"AUDI\",\"image\":\"AUDI.png\"},{\"value\":\"BENZ\",\"image\":\"BENZ.png\"},{\"value\":\"BMW\",\"image\":\"BMW.png\"},{\"value\":\"Ford\",\"image\":\"Ford.png\"},{\"value\":\"Nissan\",\"image\":\"Nissan.png\"},{\"value\":\"\",\"image\":\"Renault.png\"},{\"value\":\"\",\"image\":\"TATA.png\"},{\"value\":\"\",\"image\":\"Toyota.png\"}]",
        "customfield3": "1,Toyota.png",
        "customfield4": "0",
        "customfield5": "",
        "validations": null,
        "typeId": 1,
        "conditionText": "Select Vehicle Type.length == 10",
        "enableConditionalField": 1,
        "metaData": "{\"statement\":{\"groups\":[{\"conjunctor\":\"OR\",\"conditions\":[{\"filedName\":\"Select Vehicle Type.length\",\"operators\":\"==\",\"fieldValue\":\"3\"},{\"filedName\":\"Enter the vehicle number\",\"operators\":\"==\",\"fieldValue\":\"10\"},{\"filedName\":\"Enter the vehicle number\",\"operators\":\"==\",\"fieldValue\":\"9\"}],\"groups\":[]}]}}",
        "gaTrackingId": null,
        "dynamicFormLabelValidator": [
          {
              "entityId": 429,
              "parentId": 419,
              "name": "minLength",
              "validator": "10",  // Minimum length validator
              "message": "Minimum length is 10 characters.",
              "hintMessage": "Please enter at least 10 characters."
          },
          {
              "entityId": 429,
              "parentId": 419,
              "name": "maxLength",
              "validator": "15",  // Maximum length validator
              "message": "Maximum length is 15 characters.",
              "hintMessage": "Please enter no more than 15 characters."
          }
      ],
        "operationType": null
    },
    {
        "fieldId": 406,
        "label": "Transmission",
        "name": "Transmission",
        "inputType": "image",
        "options": "Automatic gear,Manual gear",
        "collections": null,
        "type": "",
        "width": "",
        "value": null,
        "position": "",
        "customfield1": "1",
        "customfield2": "[{\"value\":\"Automatic gear\",\"image\":\"Automaticgear.jpg\"},{\"value\":\"Manual gear\",\"image\":\"Manualgear.jpg\"}]",
        "customfield3": "1,Manualgear.jpg",
        "customfield4": "0",
        "customfield5": "",
        "validations": null,
        "typeId": 1,
        "conditionText": "Model Of The Audi Car =='A4'",
        "enableConditionalField": 1,
        "metaData": "{\"statement\":{\"groups\":[{\"conjunctor\":\"OR\",\"conditions\":[{\"filedName\":\"Model Of The Audi Car\",\"operators\":\"==\",\"fieldValue\":\"A4\"}],\"groups\":[]}]}}",
        "gaTrackingId": null,
        "dynamicFormLabelValidator": [
            {
                "entityId": 416,
                "parentId": 406,
                "name": "",
                "validator": "",
                "message": "",
                "hintMessage": ""
            }
        ],
        "operationType": null
    }
]

  ngOnInit() {


    if (this.customField13 == 1) {


      this.initSecondForm = true;
    }
    
///this.fields=this.value1;
    this.form = this.createControl();
    this.subscribeToFormChanges();

    this.valuesInputed.push(this.fields[0].name)
    this.formInit = true;
    this.previousFormField = this.fields[0].name;
    this.objectTobeDisplayed = this.fields[0];
    this.formInit1 = true;
    this.indexNew = 0;

    this.EnablePayment = this.customField5;
  }

  onSubmit() {
    if (this.form.valid) {
      this.submit.emit(this.form.value);
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  createControl() {
    const group = this.fb.group({});
    this.fields.forEach(field => {
      if (field.type === "button") return;
      const control = this.fb.control(
        field.value,
        this.bindValidations(field['dynamicFormLabelValidator'] || [])
      );
      group.addControl(field.name, control);
    });
    return group;
  }

  subscribeToFormChanges() {
    this.form.valueChanges.subscribe(changes => {
      const changedField = Object.keys(changes).find(key => this.form.get(key)?.dirty);

      if (changedField) {
        const fieldConfig = this.fields.find(field => field.name === changedField);
        const fieldInputType = fieldConfig ? fieldConfig.inputType : null;

        if (!this.excludedInputTypes.has(fieldInputType)&&this.customField13==1 ) {
          this.subscriptionService.updateFormValue(this.form);
          this.updateConditionalFieldsVisibility();
        }else if(this.customField13==0){
          this.subscriptionService.updateFormValue(this.form);
          this.updateConditionalFieldsVisibility();
        }
      }
    });
  }
  updateConditionalFieldsVisibility() {
    this.fields.forEach(field => {
      if (field.enableConditionalField == 1) {
        const conditionResult = this.evaluateCondition(field.conditionText, this.form.value);
  
        if (conditionResult) {
          let index = this.valuesInputed.findIndex(x => x == field.name);
  
          if (index < 0) {
            this.objectTobeDisplayed = field;
            this.formInit1 = true;
            this.valuesInputed.push(this.objectTobeDisplayed.name);
            this.indexInital = this.indexInital + 1;
          }
  
          if (this.valuesInputed.length > 1) {
            this.dontDisplayBackButton = true;
          }
        }
  
        field.visible = conditionResult;
      }
    });
  }
  
  evaluateCondition(conditionText: string, formValues: any): boolean {
    if (!conditionText) return true; // If no condition specified, return true
    return this.evaluateExpression(conditionText, formValues);
  }
  
  evaluateExpression(expression: string, formValues: any): boolean {
    if (!expression) return true;
  
    expression = expression.replace(/\s+or\s+/g, ' || ').replace(/\s+and\s+/g, ' && ');
  
    const blocks = expression.split(/\s*\|\|\s*/);
  
    for (const block of blocks) {
      // Split block by AND (&&) operator
      const conditions = block.split(/\s*&&\s*/);
      let allConditionsTrue = true;
  
      for (const condition of conditions) {
        const conditionResult = this.evaluateSingleCondition(condition.trim(), formValues);
        if (!conditionResult) {
          allConditionsTrue = false;
          break;
        }
      }
  
      if (allConditionsTrue) {
        return true; // If all conditions in a block are true, the OR block is true
      }
    }
  
    return false; // If none of the OR blocks are true
  }
  
  evaluateSingleCondition(condition: string, formValues: any): boolean {
    let [variable, operator, value] = condition.split(/\s*(===|!==|==|!=|<|>|<=|>=)\s*/);
  
    if (variable.includes('.length')) {
      const varWithoutLength = variable.replace('.length', '').trim();
      const variableValue = (formValues[varWithoutLength] as string)?.length ?? 0;
      const lengthValue = parseInt(value, 10);
  
      switch (operator) {
        case '===':
        case '==':
          return variableValue === lengthValue;
        case '!==':
        case '!=':
          return variableValue !== lengthValue;
        case '<':
          return variableValue < lengthValue;
        case '>':
          return variableValue > lengthValue;
        case '<=':
          return variableValue <= lengthValue;
        case '>=':
          return variableValue >= lengthValue;
        default:
          return false;
      }
    } else {
      const variableValue = formValues[variable.trim()];
      const cleanValue = value.replace(/'/g, '');
      const variableValueNumber = parseFloat(variableValue);
  
      switch (operator) {
        case '===':
          return variableValue === cleanValue;
        case '!==':
          return variableValue !== cleanValue;
        case '==':
          return variableValue == cleanValue || variableValueNumber == parseFloat(cleanValue);
        case '!=':
          return variableValue != cleanValue && variableValueNumber != parseFloat(cleanValue);
        case '<':
          return variableValueNumber < parseFloat(cleanValue);
        case '>':
          return variableValueNumber > parseFloat(cleanValue);
        case '<=':
          return variableValueNumber <= parseFloat(cleanValue);
        case '>=':
          return variableValueNumber >= parseFloat(cleanValue);
        default:
          return false;
      }
    }
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      let minLength: number | null = null;
      let maxLength: number | null = null;
  
      validations.forEach(valid => {
        if (valid.name === 'required') {
          validList.push(Validators.required);
        } else if (valid.name === 'pattern') {
          validList.push(Validators.pattern(valid.validator));
        } else if (valid.name === 'minLength') {
          if (valid.validator) {
            minLength = parseInt(valid.validator, 10); // Convert string to number
          }
        } else if (valid.name === 'maxLength') {
          if (valid.validator) {
            maxLength = parseInt(valid.validator, 10); // Convert string to number
          }
        }
        // Add more validations here if needed
      });
  
      // Apply minLength and maxLength validators if values are set
      if (minLength !== null) {
        validList.push(Validators.minLength(minLength));
      }
      if (maxLength !== null) {
        validList.push(Validators.maxLength(maxLength));
      }
  
      return Validators.compose(validList);
    }
    return null;
  }
  
  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  calculateFlexValue(layout: number, gapBetweenImage: boolean): string {
    const baseFlex = layout === 2 ? '50%' :
      layout === 3 ? '33.33%' :
        layout === 4 ? '25%' : '100%';
    return gapBetweenImage ? `calc(${baseFlex} - ${gapBetweenImage})` : baseFlex;
  }

  indexInital: any = 0;

 
  onFieldValueChange(value: any) {

    this.previousFormField = value;
 
    
    this.previousFormFieldsList.push(value);

    this.subscribeToFormChangesText()


  }

  onFieldValueChange1(value: any) {

    if(value == true){
      this.formInitSecond = true;
    }


  }
  Gotonextform(){
    this.formInitSecond=true
  }


  onFieldValueChangeImage(value: any) {
    this.imageList = value;
    this.onFieldValueChange(value);

    this.formInitSecond = true;

  }

  SubmitForm(object) {

    if (object.operationType = "New") {
      this.productListiingService.submitProductListing(object).subscribe(
        (response) => {
          if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
            this.snackBar.open(response['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
            this.router.navigate(['/home']);
            
          }
          else {
            this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          }
        },
        (err) => { this.text = "Submit" });
    }
    else {

    }



  }

  getIndex() {

    this.indexInital = this.indexInital;



  }

  back() {

    let length = 0;


    let newLength = this.valuesInputed.length - 1;


    let indexNew = this.fields.findIndex(x => x['name'] == this.valuesInputed[newLength]);

    if (indexNew > -1) {

      if (this.form.value[this.fields[indexNew].name] != '' && this.form.value[this.fields[indexNew].name] != null) {
        this.form.get(this.fields[indexNew].name).setValue('');
      }

    }
  indexNew = this.fields.findIndex(x => x['name'] == this.valuesInputed[newLength-1]);

    if (indexNew > -1) {

      if (this.form.value[this.fields[indexNew].name] != '' && this.form.value[this.fields[indexNew].name] != null) {
        this.form.get(this.fields[indexNew].name).setValue('');
      }

    }



    if (this.valuesInputed.length == 1) {
      this.dontDisplayBackButton = false;
      length = 0;
    } else {

      this.valuesInputed = this.valuesInputed.slice(0, -1);
      length = this.valuesInputed.length - 1;

    }


    if (length == 0) {
      this.dontDisplayBackButton = false;
    }

    let index = this.fields.findIndex(x => x['name'] == this.valuesInputed[length]);

    if (index > -1) {

      this.objectTobeDisplayed = this.fields[index];
    }


  }

  goToState(index: number) {
    if (index >= 0) {
      
      for (let i = index + 1; i < this.fields.length; i++) {
        if (this.form.get(this.fields[i].name)) {
          this.form.get(this.fields[i].name).setValue('');
        }
      }
  
      this.valuesInputed = this.fields.slice(0, index + 1).map(field => field.name);
  
      if (index == 0) {
        this.valuesInputed = [this.fields[0].name];
        this.dontDisplayBackButton = false;
      }
  
      this.objectTobeDisplayed = this.fields[index];
    }
  }
  

  submitSecondForm(value) {

    console.log(value);
    
    const mergedObj = { ...this.form.value, ...value };

    mergedObj.productListingImages = this.imageList;

    mergedObj['value'] = JSON.stringify(mergedObj);
    this.SubmitForm(mergedObj)


  }

  
  subscribeToFormChangesText() {
  

      this.subscriptionService.updateFormValue(this.form);
      
      this.updateConditionalFieldsVisibility();
   
  }


}