import { Component, Input, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { CalsoftService } from 'src/app/services/Calsoft.service';
import { CartService } from 'src/app/services/cart.service';
import { LoaderService } from 'src/app/services/loader.service';
import { UserService } from 'src/app/services/user.service';
import { CommonSignInComponent } from 'src/app/shared/CommonSignIn/CommonSignIn.component';
import { CitySelectionComponent } from 'src/app/theme/city-selection/city-selection.component';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { OnChanges } from '@angular/core';
declare var $: any;

@Component({
  selector: 'calsoft-mobile-header7',
  templateUrl: './mobile-header7.component.html',
  styleUrls: ['./mobile-header7.component.scss']
})
export class MobileHeader7Component {
  selectedCity: any;


  openCitySelectionDialog() {
     if (window.innerWidth > 768) {

        let dialogRef = this.dialog.open(CitySelectionComponent, {
           width: '790px',
        });

        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(result => {
           this.selectedCity = result;
        });

     }
     else {
        let dialogRef = this.dialog.open(CitySelectionComponent, {
           minWidth: '100vw', height: '100vh',
        });

        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(result => {
           this.selectedCity = result;

        });
     }

  }

  public serverPath = environment.commonImageApi + "logo/";
  @Input() languageSelection: any;
  @Input() data: any;
  toggleActive: boolean = false;
  cartProducts: any;
  popupResponse: any;
  wishlistProducts: any;
  userLoggedIn: boolean;
  page: string;
  page1: any;
  cartTotalItems: any;
  public form: UntypedFormGroup;
  url: string;
  countryCode: Subject<any> = this.loaderService.countryCode;
  index: any;
  @Input() item: any;
  display: boolean;
  mobileHeaderType: any = 0;
  list1: any;
  list2: any;


  constructor(public calsoftService: CalsoftService,
     
     private router: Router,
     private basicAuthenticationService: UserService,
     private dialog: MatDialog,
     private userService: UserService,
     public cartServices: CartService,
     private loaderService: LoaderService,
     private location: Location,
     

  ) { }
  ngOnChanges() {
     const middleIndex = Math.ceil(this.item.length / 2);
     this.list1 = this.item.slice(0, 2);
     this.list2 = this.item.slice(3, this.item.length + 1);
  }


  userLogged() {

     this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
     };

     let basicAuthHeaderString = this.basicAuthenticationService.getAuthenticatedToken();
     let username = this.basicAuthenticationService.getAuthenticatedUser();

     if (basicAuthHeaderString && username) {

        this.userLoggedIn = true;
        this.cartServices.getCount();

     }
     else {

        this.userLoggedIn = false;
     }
  }




  loginModel() {
     if (window.innerWidth > 768) {

        let dialogRef = this.dialog.open(CommonSignInComponent, {
           width: '790px',
           data: { page: this.page1, }
        });

        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(result => {
           this.ngOnInit();


        });

     }
     else {
        let dialogRef = this.dialog.open(CommonSignInComponent, {
           minWidth: '100vw', height: '100vh',
           data: { page: this.page1, }
        });

        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(result => {
           this.ngOnInit();


        });
     }

  }

  back() {
     this.location.back();
  }


  SignIn() {
     // this.router.navigate(['/sign-in']);
     this.loginModel();
  }

  home() {
     this.router.navigate(['/'])
  }


  search() {
     this.router.navigate(['/search'])
  }

  public toggleSearch() {
     $('app-main').toggleClass('form-open');
  }

  public toggleSidebar() {
     this.calsoftService.sidenavOpen = !this.calsoftService.sidenavOpen;
  }


  public selectedCurrency(value) {
     this.calsoftService.currency = value;
  }

  public selectedLanguage(value) {
     this.calsoftService.language = value;
  }
  callPhone() {
     let phoneNumber = "tel:" + this.data.storePhoneNumber;
     window.location.href = phoneNumber;
  }
  @Input() color: any;

  //logoPositionMobile:boolean = false;
  mobileLogoEnable:any=0;
  mobileLogoSrc:any;
  mobileHeaderHeight:any;
  mobileHeaderLogoGap:any;

  layout:any=0;
  headerIconColour:any;
  displaySearchBar: boolean = false;


  public serverPath3 = environment.commonImageApi + "mobileImage/";
  disableCartIconInHeader: any=0;
  disableLoginHeader: any =0;
  disableWishlistHeader: any =0;



  ngOnInit(): void {   

    this.userLogged();
     this.url = this.router.url;

     if (window.innerWidth < 1281) {
        this.display = false;
     } else {
        this.display = true
     }

     this.mobileHeaderType = this.data['mobileHeaderType'];
    
    if(this.data!=undefined){
      if(this.data['logoPositionMobile']!=undefined){
        this.layout=this.data['logoPositionMobile'];
      }

      if(this.data['headerIconColour']!=undefined){
        this.headerIconColour=this.data['headerIconColour'];
      }

      if(this.data['mobileLogoEnable']!=undefined){
        this.mobileLogoEnable=this.data['mobileLogoEnable'];
      }

      if(this.data['mobileHeaderHeight']!=undefined){
        this.mobileHeaderHeight=this.data['mobileHeaderHeight'];
      }

      if(this.data['mobileLogoSrc']!=undefined){
        this.mobileLogoSrc=this.data['mobileLogoSrc'];
      }

      if(this.data['mobileHeaderLogoGap']!=undefined){
        this.mobileHeaderLogoGap=this.data['mobileHeaderLogoGap'];
      }

      if(this.data['disableCartIconInHeader']!=undefined){
        this.disableCartIconInHeader=this.data['disableCartIconInHeader'];
      }


      if (this.data['disableLoginHeader'] != undefined) {
        this.disableLoginHeader = this.data['disableLoginHeader'];
      }

      if (this.data['disableWishlistHeader'] != undefined) {
        this.disableWishlistHeader = this.data['disableWishlistHeader'];
      }

      

    }
    
    
    this.userLogged();

  }


  cart() {
    let basicAuthHeaderString = this.userService.getAuthenticatedToken();
    let username = this.userService.getAuthenticatedUser();

    if (basicAuthHeaderString && username) {
      this.router.navigate(['/cart'])

    }
    else {
      this.page1 = "cart";
      this.loginModel();

      // this.router.navigate(['/cart'])

    }

  }




hideSearch() {
  this.displaySearchBar = false;
  }

  showSearchBar(){
  this.displaySearchBar = true;
  }



}



